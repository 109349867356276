@use "_mantine" as mantine;
@use "../styles/variables";

.layoutMain {
  display: flex;
  flex-flow: column;
  height: 100%;

  .content {
    flex: 1 1 auto;
  }

  .hero {
    flex: 0 1 200px;
  }

  .footer {
    flex: 0 1 auto;
  }
}

.stayAt {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;

  .highlight {
    color: var(--mantine-primary-color-filled);
  }
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;

  margin-top: calc(var(--mantine-spacing-xl) * 1.5);
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);

  @media (max-width: mantine.$mantine-breakpoint-xs) {
    flex-direction: column;
    align-items: center;
    gap: 18px;
  }
}

.control {
  height: 42px;
  font-size: var(--mantine-font-size-md);

  &:not(:first-of-type) {
    margin-left: var(--mantine-spacing-md);
  }

  @media (max-width: mantine.$mantine-breakpoint-xs) {
    &:not(:first-of-type) {
      margin-top: var(--mantine-spacing-md);
      margin-left: 0;
    }
  }
}

.secondaryControl {
  color: var(--mantine-color-white);
  background-color: rgba(255, 255, 255, 0.4);

  @mixin hover {
    background-color: rgba(255, 255, 255, 0.45);
  }
}

.datesInputWrapper {
  max-width: 205px;
}

.peopleInputWrapper {
  max-width: 186px;
}

.browse {
  margin: 32px auto;
  text-align: center;
  color: var(--mantine-color-white);

  a {
    color: var(--mantine-primary-color-filled);
    font-weight: 700;
    text-decoration: none;
  }
}