@use "_mantine" as mantine;
@use '../../styles/variables';

.input {
  width: 280px;

  // Phone (portrait)
  @media (max-width: variables.$phone-max-width) and (orientation: portrait) {
    width: 200px;
  }
}

.placeName {
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 500;
}

.placeRegion {
  margin: 3px 3px 0px 3px;
  font-size: 0.75rem;
  line-height: 1rem;
  // color: #7a7a7a;
}
