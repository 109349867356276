@use "_mantine" as mantine;
.wrapper {
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 180px;
  padding-bottom: 130px;

  @media (max-width: mantine.$mantine-breakpoint-xs) {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

.inner {
  position: relative;
  z-index: 1;
}

.title {
  margin-bottom: var(--mantine-spacing-xs);
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  font-family:
          Greycliff CF,
          var(--mantine-font-family);
  font-weight: 800;
  font-size: 40px;
  letter-spacing: -1px;
  text-align: center;
  color: var(--mantine-color-white);

  @media (max-width: mantine.$mantine-breakpoint-xs) {
    font-size: 28px;
    // text-align: left;
  }
}

.description {
  margin-top: 2rem;
  font-size: calc(var(--mantine-font-size-lg) + 0.3em);
  text-align: center;
  color: var(--mantine-color-gray-0);

  @media (max-width: mantine.$mantine-breakpoint-xs) {
    font-size: var(--mantine-font-size-md);
    // text-align: left;
  }
}
