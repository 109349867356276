@use "_mantine" as mantine;
.wrapper {
  padding-top: calc(var(--mantine-spacing-xl) * 4);
  padding-bottom: calc(var(--mantine-spacing-xl) * 4);
}

.title {
  margin-bottom: var(--mantine-spacing-md);
  font-family:
          Greycliff CF,
          var(--mantine-font-family);
  font-weight: 900;
  text-align: center;

  @media (max-width: mantine.$mantine-breakpoint-sm) {
    font-size: 28px;
    text-align: left;
  }
}

.description {
  text-align: center;

  @media (max-width: mantine.$mantine-breakpoint-sm) {
    text-align: left;
  }
}
